import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import React from "react";

function RadioBox({
  text,
  checked = false,
  icon = "",
  onClick = () => {},
  className,
}) {
  return (
    <div className={`radiobox `} onClick={onClick}>
      <div className={`radio-box ${checked ? "checked" : ""} ${className}`}>
        <CheckIcon />
      </div>

      <span className="radiobox-text">{text}</span>

      {icon && <div className="radiobox-right-icon">{icon}</div>}
    </div>
  );
}

export default RadioBox;
