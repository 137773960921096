import React from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";

function PageTitle({
  title,
  icon,
  showarchive,
  messageNo,
  archiveNo,
  className,
  iconLeft,
}) {
  const lang = useSelector((state) => state.lang);

  return (
    <div className={`page-title ${className}`}>
      {iconLeft}
      <span data-lang={lang}>{title}</span>
      {icon ? (
        <div className="icon-cont" onClick={showarchive}>
          (
          {title === "message" ? (
            archiveNo <= 0 ? null : (
              <div className="icon-number">{archiveNo}</div>
            )
          ) : messageNo <= 0 ? null : (
            <div className="icon-number">{messageNo}</div>
          )}
          ){icon}
        </div>
      ) : null}
    </div>
  );
}

export default PageTitle;
