import { ReactComponent as FreeIcon } from "../../assets/icons/free.svg";
import { ReactComponent as PaidIcon } from "../../assets/icons/paid.svg";
import { ReactComponent as FreemiumIcon } from "../../assets/icons/freemium.svg";
import React from "react";

import { ReactComponent as AppleIcon } from "../../assets/icons/apple.svg";
import { ReactComponent as WindowsIcon } from "../../assets/icons/windows.svg";
import { ReactComponent as BrowserIcon } from "../../assets/icons/browser.svg";
import { ReactComponent as LinuxIcon } from "../../assets/icons/linux.svg";
import { ReactComponent as AndroidIcon } from "../../assets/icons/android.svg";

import { useEffect, useState } from "react";

function CardSpecs({
  platform = [],
  price = "",
  tags = [],
  category,
  linkAndroid,
  linkWindows,
  link,
}) {
  const [icons, setIcons] = useState([]);
  const [appPlatform, setAppPlatform] = useState([]);

  const isValidLink = (link) => {
    if (
      link !== undefined &&
      link !== "undefined" &&
      link !== null &&
      link.length > 1
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    let resIcons = [];
    let resAppPlatform = [];

    // price
    if (price === "Free") {
      resIcons = [...resIcons, <FreeIcon />];
    } else if (price === "Paid") {
      resIcons = [...resIcons, <PaidIcon />];
    } else if (price === "Freemium") {
      resIcons = [...resIcons, <FreemiumIcon />];
    }

    // platform
    if (platform.includes("Apple")) {
      if (category === "apps") {
        if (isValidLink(link)) {
          resAppPlatform = [
            ...resAppPlatform,
            <AppleIcon
              onClick={() => {
                window.open(link, "_blank");
              }}
            />,
          ];
        }
      } else {
        resIcons = [...resIcons, <AppleIcon />];
      }
    }
    if (platform.includes("Windows")) {
      if (category === "apps") {
        if (isValidLink(linkWindows)) {
          resAppPlatform = [
            ...resAppPlatform,
            <WindowsIcon
              onClick={() => {
                window.open(linkWindows, "_blank");
              }}
            />,
          ];
        }
      } else {
        resIcons = [...resIcons, <WindowsIcon />];
      }
    }
    if (platform.includes("Browser")) {
      resIcons = [...resIcons, <BrowserIcon />];
    }
    if (platform.includes("Linux")) {
      resIcons = [...resIcons, <LinuxIcon />];
    }
    if (platform.includes("Android")) {
      if (category === "apps") {
        if (isValidLink(linkAndroid)) {
          resAppPlatform = [
            ...resAppPlatform,
            <AndroidIcon
              onClick={() => {
                window.open(linkAndroid, "_blank");
              }}
            />,
          ];
        }
      } else {
        resIcons = [...resIcons, <AndroidIcon />];
      }
    }

    // update icons
    setIcons(resIcons);
    setAppPlatform(resAppPlatform);
  }, [price, platform, category, linkAndroid, linkWindows, link]);

  return (
    <>
      {category && category === "apps" ? (
        <div className="app-card-specs">
          <div className="card-specs-platform-price">
            {icons.map((icon, key) => {
              return <div key={key}>{icon}</div>;
            })}
            <div className="card-specs-tags">
              {tags.map((tag, key) => {
                return <span key={key}>{tag}</span>;
              })}
            </div>
          </div>

          <div className="card-specs-apps-platform">
            {appPlatform.map((icon, key) => {
              return <div key={key}>{icon}</div>;
            })}
          </div>
        </div>
      ) : (
        <div className="card-specs">
          <div className="card-specs-platform-price">
            {icons.map((icon, key) => {
              return <div key={key}>{icon}</div>;
            })}
          </div>

          <div className="card-specs-tags">
            {tags.map((tag, key) => {
              return <span key={key}>{tag}</span>;
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default CardSpecs;
