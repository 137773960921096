import RadioBox from "../atoms/RadioBox";
import { useSelector } from "react-redux";
import React from "react";

function RadioBoxGroup({
  checks,
  value = [],
  onChange = () => {},
  className,
  error,
}) {
  const handleCheck = (val) => {
    let result = value;

    if (result === val) {
      result = "";
    } else {
      result = val;
    }
    console.log(result);
    onChange(result);
  };
  const lang = useSelector((state) => state.lang);

  return (
    <div className={`radiobox-group ${className} `}>
      {checks.map((check, key) => {
        return (
          <RadioBox
            key={key}
            text={lang == "en" || !check.textJp ? check.text : check.textJp}
            checked={value == check.label}
            icon={check.icon || null}
            onClick={() => {
              handleCheck(check.label || check.text);
            }}
            className={error ? "input-error-small" : ""}
          />
        );
      })}
    </div>
  );
}

export default RadioBoxGroup;
