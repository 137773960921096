import Layout from "../components/layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setSelectMenu } from "../redux/actions/env";
import "../styles/main.scss";
import ChatLeftBar from "../components/templates/chatLeftBar";
import MessageGrid from "../components/templates/messageGrid";
import { ReactComponent as MaileIcon } from "../assets/icons/mail.svg";
import { ReactComponent as ArchiveIcon } from "../assets/icons/archive.svg";
import {
  getChatInfo,
  fetchChats,
  toggleArchive,
  deleteChat,
} from "../services/ChatService";
import { setNotifications } from "../redux/actions/notifications";
import io from "socket.io-client";
import React from "react";
import { BACKEND_URL } from "../config";
import axios from "axios";
import jwtDecode from "jwt-decode";

const token = localStorage.getItem("token");
const socket = io.connect(BACKEND_URL, {
  withCredentials: true,
  transports: ["websocket"],
});
function Message() {

  useEffect(() => {
    document.title = "UUD - Messages";
    return () => {
      document.title = "UUD";
    };
  }, []);


  const [leftMessages, setLeftMessages] = useState([]);
  const [selectedChat, setSelectedChat] = useState([]);
  const [messages, setMessages] = useState([]);
  const [showRightBar, setShowRightBar] = useState(false);
  const [showLeftBar, setShowLeftBar] = useState(true);
  const [archive, setArchive] = useState(false);
  const [title, setTitle] = useState("message");
  const user = useSelector((state) => state.user);
  const userId = useSelector((state) => state.user_id);
  const [icon, setIcon] = useState(<ArchiveIcon />);
  const notification = useSelector((state) => state.notifications);
  const [popMessage, setPopMessage] = useState("");
  const [popType, setPopType] = useState("");
  const [IsShowPopup, settIsShowPopup] = useState(false);
  const [whole, setWhole] = useState();
  const [loading, setLoading] = useState();
  const [message, setMessage] = useState("");
  const [messageNo, setMessageNo] = useState("");
  const [archiveNo, setArchiveNo] = useState();
  const [typing, setTyping] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const dispatch = useDispatch();

  let selectedChatCompare;
  const [socketConnected, setSocketConnected] = useState(false);

  function handleTyping(e) {
    setMessage(e.target.value);
    setTyping(true);

    if (!typing) {
      setTyping(true);
      socket.emit("typing", selectedChat._id);
    }
    console.log(typing);
    let lastTypingTime = new Date().getTime();
    let timerLength = 3000;
    setTimeout(() => {
      let timeNow = new Date().getTime();
      let timeDiff = timeNow - lastTypingTime;
      if (timeDiff >= timerLength && typing) {
        socket.emit("stop typing", selectedChat._id);
        setTyping(false);
      }
    }, timerLength);
  }
  function popups(message, type) {
    settIsShowPopup(true);
    setPopType(type);
    setPopMessage(message);
    setTimeout(() => {
      settIsShowPopup(false);
    }, 2000);
  }
  const sendMessage = (content, chat, updatedAt) => {
    socket.emit("stop typing", selectedChat._id);
    axios
      .post(
        `${BACKEND_URL}/message/`,
        {
          chatId: chat._id,
          content: content,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user}`,
          },
        }
      )
      .then(({ data }) => {
        setMessage("");
        if (!data) {
          console.log("Data is not here!!");
        } else {
          console.log(data);

          socket.emit("send_message", data);

          console.log("dddd", data, messages);
          const receiver =
            data.sender._id !== data.chatId.users[0]._id
              ? data.chatId.users[0]._id
              : data.chatId.users[1]._id;

          console.log("sends a", receiver);
          console.log("sends b", userId);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    setMessages((prev) => {
      return [
        {
          content: content,
          sender: jwtDecode(user),
          updatedAt: updatedAt,
        },
        ...prev,
      ];
    });
    if (
      JSON.stringify(leftMessages.activeChatsWithContent).includes(
        JSON.stringify(chat)
      )
    ) {
      setLeftMessages((prev) => {
        let x = prev;
        x.activeChatsWithContent.find((el) => {
          return JSON.stringify(el._id) == JSON.stringify(chat._id);
        }).updatedAt = new Date();
        if (
          x.activeChatsWithContent.find((el) => {
            return JSON.stringify(el._id) == JSON.stringify(chat._id);
          }).latestMessage
        ) {
          x.activeChatsWithContent.find((el) => {
            return JSON.stringify(el._id) == JSON.stringify(chat._id);
          }).latestMessage.content = content;
        }
        return { ...x };
      });
    } else if (
      JSON.stringify(leftMessages.archivedChatsWithContent).includes(
        JSON.stringify(chat)
      )
    ) {
      setLeftMessages((prev) => {
        let x = prev;
        x.archivedChatsWithContent.find((el) => {
          return JSON.stringify(el._id) == JSON.stringify(chat._id);
        }).updatedAt = new Date();
        if (
          x.archivedChatsWithContent.find((el) => {
            return JSON.stringify(el._id) == JSON.stringify(chat._id);
          }).latestMessage
        ) {
          x.archivedChatsWithContent.find((el) => {
            return JSON.stringify(el._id) == JSON.stringify(chat._id);
          }).latestMessage.content = content;
        }
        return { ...x };
      });
    }
  };

  function receive(newMessageReceived) {
    // Your receiveMessageHandler logic goes here...

    if (newMessageReceived != undefined && selectedChat != undefined) {
      if (newMessageReceived.chatId._id == selectedChat._id) {
        setMessages((messages) => [newMessageReceived, ...messages]);

        setLeftMessages((prev) => {
          prev.activeChatsWithContent.find((el) => {
            return el._id == newMessageReceived.chatId._id;
          }).latestMessage.content = newMessageReceived.content;
          prev.activeChatsWithContent.find((el) => {
            return el._id == newMessageReceived.chatId._id;
          }).updatedAt = new Date();

          return { ...prev };
        });

        axios
          .post(
            `${BACKEND_URL}/message/message-as-read/${newMessageReceived._id}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token.slice(1, -1)}`,
              },
            }
          )
          .then(() => {})
          .catch(() => {});
      } else {
        setLeftMessages((prev) => {
          prev.activeChatsWithContent.find((el) => {
            return el._id == newMessageReceived.chatId._id;
          }).latestMessage.content = newMessageReceived.content;
          prev.activeChatsWithContent.find((el) => {
            return el._id == newMessageReceived.chatId._id;
          }).updatedAt = new Date();
          prev.activeChatsWithContent.find((el) => {
            return el._id == newMessageReceived.chatId._id;
          }).latestMessage._id = newMessageReceived._id;

          prev.activeChatsWithContent.find((el) => {
            return el._id == newMessageReceived.chatId._id;
          }).latestMessage.isRead = false;

          return { ...prev };
        });
      }
    }
  }
  useEffect(() => {
    socket.auth = { userId };
    console.log(selectedChat ? selectedChat : null);
    socket.on("typing", () => setIsTyping(true));
    socket.on("stop typing", () => setIsTyping(false));
    console.log("oN");
    socket.on("receive_message", (res) => receive(res));

    return () => {
      socket.off("typing");
      socket.off("stop typing");
      console.log("off");
      socket.off("receive_message");
    };
  }, [selectedChat]);
  useEffect(() => {
    socket.emit("join_room", jwtDecode(user).id);
  }, []);
  useEffect(() => {
    if (leftMessages.length != 0 && selectedChat.latestMessage != undefined) {
      axios
        .post(
          `${BACKEND_URL}/message/message-as-read/${selectedChat.latestMessage._id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token.slice(1, -1)}`,
            },
          }
        )
        .then(() => {})
        .catch(() => {});
      setLeftMessages((prev) => {
        if (
          prev.activeChatsWithContent.find((el) => {
            return el._id == selectedChat._id;
          })?.latestMessage != undefined
        ) {
          prev.activeChatsWithContent.find((el) => {
            return el._id == selectedChat._id;
          }).latestMessage.isRead = true;
        }
        let counter = 0;
        prev.activeChatsWithContent.map((el) => {
          if (el.latestMessage) {
            if (
              el.latestMessage.isRead == false &&
              el.latestMessage.sender._id != jwtDecode(user).id
            ) {
              counter++;
            }
          }
        });
        setMessageNo(counter);
        let counter1 = 0;
        prev.archivedChatsWithContent.map((el) => {
          if (el.latestMessage) {
            if (
              el.latestMessage.isRead == false &&
              el.latestMessage.sender._id != jwtDecode(user).id
            ) {
              counter1++;
            }
          }
        });
        setArchiveNo(counter1);
        return {
          ...prev,
        };
      });
    }
  }, [selectedChat]);
  useEffect(() => {
    if (leftMessages.length != 0) {
      let counter = 0;
      leftMessages.activeChatsWithContent.map((el) => {
        if (el.latestMessage) {
          if (
            el.latestMessage.isRead == false &&
            el.latestMessage.sender._id != jwtDecode(user).id
          ) {
            counter++;
          }
        }
      });
      setMessageNo(counter);
      let counter1 = 0;
      leftMessages.archivedChatsWithContent.map((el) => {
        if (el.latestMessage) {
          if (
            el.latestMessage.isRead == false &&
            el.latestMessage.sender._id != jwtDecode(user).id
          ) {
            counter1++;
          }
        }
      });
      setArchiveNo(counter1);
    }
  }, [leftMessages]);

  function showarchive() {
    if (title == "message") {
      setLoading(true);
      setTitle("archive");
      setIcon(<MaileIcon />);
      setArchive(true);
      setSelectedChat(leftMessages.archivedChatsWithContent[0]);
      setMessages([]);
      const req2 = async () => {
        return await axios.get(
          `${BACKEND_URL}/message/${leftMessages.archivedChatsWithContent[0]._id}`,
          {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          }
        );
      };
      req2().then((result) => {
        if (result != undefined) {
          setMessages(() => {
            return result.data.reverse();
          });
        }
      });
    } else if (title == "archive") {
      setLoading(true);
      setTitle("message");
      setIcon(<ArchiveIcon />);
      setArchive(false);
      setSelectedChat(leftMessages.activeChatsWithContent[0]);
      const req1 = async () => {
        return await axios.get(
          `${BACKEND_URL}/message/${leftMessages.activeChatsWithContent[0]._id}`,
          {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          }
        );
      };
      req1().then((result) => {
        if (result != undefined) {
          setMessages(() => {
            return result.data.reverse();
          });
        }
      });
    }
  }
  useEffect(() => {
    const req = async () => {
      return axios.get(`${BACKEND_URL}/chats/`, {
        headers: {
          Authorization: `Bearer ${token.slice(1, -1)}`,
        },
      });
    };

    req().then((result) => {
      if (result != undefined) {
        setWhole(result.data);
        setLeftMessages(result.data);

        let counter = 0;
        result.data.activeChatsWithContent.map((el) => {
          if (el.latestMessage) {
            if (
              el.latestMessage.isRead === false &&
              el.latestMessage.sender._id != jwtDecode(user).id
            ) {
              counter++;
            }
          }
        });
        setMessageNo(counter);
        let counter1 = 0;
        result.data.archivedChatsWithContent.map((el) => {
          if (el.latestMessage) {
            if (
              el.latestMessage.isRead == false &&
              el.latestMessage.sender._id != jwtDecode(user).id
            ) {
              counter1++;
            }
          }
        });
        setArchiveNo(counter1);

        if (
          (new Date().getTime() -
            new Date(
              result.data.activeChatsWithContent.sort(function (a, b) {
                return new Date(b.updatedAt) - new Date(a.updatedAt);
              })[0].updatedAt
            ).getTime()) /
            1000 <
          7
        ) {
          setSelectedChat(
            result.data.activeChatsWithContent.sort(function (a, b) {
              return new Date(b.updatedAt) - new Date(a.updatedAt);
            })[0]
          );
          const req4 = async () => {
            return await axios.get(
              `${BACKEND_URL}/message/${
                result.data.activeChatsWithContent.sort(function (a, b) {
                  return new Date(b.updatedAt) - new Date(a.updatedAt);
                })[0]._id
              }`,
              {
                headers: {
                  Authorization: `Bearer ${user}`,
                },
              }
            );
          };
          req4().then((res) => {
            if (res.data != undefined) {
              setMessages(() => {
                return res.data.reverse();
              });
            }

            if (selectedChat.latestMessage) {
              if (selectedChat.latestMessage.isRead == false) {
                axios
                  .post(
                    `${BACKEND_URL}/message/message-as-read/${selectedChat.latestMessage._id}`,
                    {},
                    {
                      headers: {
                        Authorization: `Bearer ${token.slice(1, -1)}`,
                      },
                    }
                  )
                  .then(() => {})
                  .catch(() => {});

                setLeftMessages((prev) => {
                  if (
                    prev.activeChatsWithContent.find((el) => {
                      return el._id == selectedChat._id;
                    }).latestMessage != undefined
                  ) {
                    prev.activeChatsWithContent.find((el) => {
                      return el._id == selectedChat._id;
                    }).latestMessage.isRead = true;
                  }
                  let counter = 0;
                  prev.activeChatsWithContent.map((el) => {
                    if (el.latestMessage) {
                      if (
                        el.latestMessage.isRead == false &&
                        el.latestMessage.sender._id != jwtDecode(user).id
                      ) {
                        counter++;
                      }
                    }
                  });
                  setMessageNo(counter);
                  let counter1 = 0;
                  prev.archivedChatsWithContent.map((el) => {
                    if (el.latestMessage) {
                      if (
                        el.latestMessage.isRead == false &&
                        el.latestMessage.sender._id != jwtDecode(user).id
                      ) {
                        counter1++;
                      }
                    }
                  });
                  setArchiveNo(counter1);
                  return {
                    ...prev,
                  };
                });
              }
            }
          });
        }
      }
    });
  }, []);
  function handleArchive(chat) {
    if (
      JSON.stringify(leftMessages.activeChatsWithContent).includes(
        JSON.stringify(chat)
      )
    ) {
      toggleArchive(chat._id, false).then((res) => {});
      setLeftMessages((prev) => {
        let x = prev;

        x.archivedChatsWithContent.push(chat);
        x.activeChatsWithContent = prev.activeChatsWithContent.filter((el) => {
          return JSON.stringify(el) != JSON.stringify(chat);
        });

        return { ...x };
      });
      setSelectedChat("");
    } else if (
      JSON.stringify(leftMessages.archivedChatsWithContent).includes(
        JSON.stringify(chat)
      )
    ) {
      toggleArchive(chat._id, true).then((res) => {});
      setLeftMessages((prev) => {
        let x = prev;

        x.activeChatsWithContent.push(chat);
        x.archivedChatsWithContent = prev.archivedChatsWithContent.filter(
          (el) => {
            return JSON.stringify(el) != JSON.stringify(chat);
          }
        );

        return { ...x };
      });
      showarchive(false);
      setSelectedChat(chat);
    }
  }
  function getSender(chat) {
    return chat.users.find((other_user) => {
      return other_user.username !== jwtDecode(user).username;
    });
  }
  function handleRemove(chat) {
    if (
      JSON.stringify(leftMessages.activeChatsWithContent).includes(
        JSON.stringify(chat)
      )
    ) {
      setLeftMessages((prev) => {
        let x = prev;
        x.activeChatsWithContent = prev.activeChatsWithContent.filter((el) => {
          return JSON.stringify(el) != JSON.stringify(chat);
        });

        return { ...x };
      });
      setSelectedChat("");
    } else if (
      JSON.stringify(leftMessages.archivedChatsWithContent).includes(
        JSON.stringify(chat)
      )
    ) {
      setLeftMessages((prev) => {
        let x = prev;
        x.archivedChatsWithContent = prev.archivedChatsWithContent.filter(
          (el) => {
            return JSON.stringify(el) != JSON.stringify(chat);
          }
        );
        return { ...x };
      });
      popups("User deleted successfully", "success");
      setSelectedChat("");
    }
    popups("User deleted successfully", "success");
  }
  function handleChatChange(chat) {
    if (chat._id != selectedChat._id) {
      setLoading(true);
      const req1 = async () => {
        return await axios.get(`${BACKEND_URL}/message/${chat._id}`, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
      };
      req1().then((result) => {
        if (result != undefined) {
          setMessages(() => {
            return result.data.reverse();
          });
          setSelectedChat(chat);
          if (chat.latestMessage) {
            if (chat.latestMessage.isRead == false) {
              axios
                .post(
                  `${BACKEND_URL}/message/message-as-read/${chat.latestMessage._id}`,
                  {},
                  {
                    headers: {
                      Authorization: `Bearer ${token.slice(1, -1)}`,
                    },
                  }
                )
                .then(() => {})
                .catch(() => {});
              if (leftMessages.length != 0) {
                setLeftMessages((prev) => {
                  if (
                    prev.activeChatsWithContent.find((el) => {
                      return el._id == chat._id;
                    }).latestMessage != undefined
                  ) {
                    prev.activeChatsWithContent.find((el) => {
                      return el._id == chat._id;
                    }).latestMessage.isRead = true;
                  }
                  let counter = 0;
                  prev.activeChatsWithContent.map((el) => {
                    if (el.latestMessage) {
                      if (
                        el.latestMessage.isRead == false &&
                        el.latestMessage.sender._id != jwtDecode(user).id
                      ) {
                        counter++;
                      }
                    }
                  });
                  setMessageNo(counter);
                  let counter1 = 0;
                  prev.archivedChatsWithContent.map((el) => {
                    if (el.latestMessage) {
                      if (
                        el.latestMessage.isRead == false &&
                        el.latestMessage.sender._id != jwtDecode(user).id
                      ) {
                        counter1++;
                      }
                    }
                  });
                  setArchiveNo(counter1);
                  return {
                    ...prev,
                  };
                });
              }
            }
          }
        }
      });
    }
    if (window.innerWidth < 960) {
      setShowLeftBar(false);
    }
  }

  const selectMenu = useSelector((state) => state.selectMenu);

  return (
    <>
      <Layout
        id="home"
        selectedMenu={selectMenu}
        currentPage="chat"
        pageTitle={"Message"}
        selectedChat={selectedChat}
        loading={loading}
        messageNo={messageNo}
        filter={
          <ChatLeftBar
            setShowLeftBar={(res) => setShowLeftBar(res)}
            leftMessages={leftMessages}
            handleChatChange={(res) => {
              handleChatChange(res);
            }}
            archiveNo={archiveNo}
            selectedChat={selectedChat}
            title={title}
            icon={icon}
            showarchive={showarchive}
            messageNo={messageNo}
          />
        }
        isHome={false}
        isLogin={user !== "" && user}
        setShowLeftBar={(res) => setShowLeftBar(res)}
        setShowRightBar={(res) => setShowRightBar(res)}
        showRightBar={showRightBar}
        showLeftBar={showLeftBar}
      >
        <MessageGrid
          archivedMessages={leftMessages.archivedChatsWithContent}
          messages={messages}
          selectedChat={selectedChat}
          sendMessage={(res, res0, res1) => sendMessage(res, res0, res1)}
          handleArchive={(res) => handleArchive(res)}
          handleRemove={(res) => handleRemove(res)}
          loading={loading}
          setLoading={(res) => setLoading(res)}
          message={message}
          setMessage={(res) => setMessage(res)}
          handleTyping={(res) => handleTyping(res)}
          IsShowPopup={IsShowPopup}
          popType={popType}
          popMessage={popMessage}
          popups={(res, res1) => popups(res, res1)}
          getSender={(res) => getSender(res)}
        />
      </Layout>
    </>
  );
}

export default Message;
