import { useEffect, useRef, useState } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as NameCardIcon } from "../../assets/icons/name_card.svg";
import { ReactComponent as Account } from "../../assets/icons/account.svg";
import { ReactComponent as Tag } from "../../assets/icons/tag.svg";
import { ReactComponent as OK } from "../../assets/icons/ok.svg";
import { ReactComponent as ViewLinkIcon } from "../../assets/icons/view_link.svg";
import { ReactComponent as TagIcon } from "../../assets/icons/tag.svg";
import { ReactComponent as PlaceholderIcon } from "../../assets/icons/logo_placeholder.svg";
import { ReactComponent as ImgIcon } from "../../assets/icons/image.svg";
import { ReactComponent as IntroIcon } from "../../assets/icons/user_1.svg";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";
import { ReactComponent as Stop } from "../../assets/icons/stop.svg";
import { ReactComponent as Save } from "../../assets/icons/save.svg";
import ActionButton from "../atoms/ActionButton";
import ItemCard from "../organismes/ItemCard";
import AvatarCrop from "../molecules/AvatarCorp";
import RadioBoxGroup from "./RadioBoxGroup";
import { useSelector } from "react-redux/es/hooks/useSelector";
import CheckBoxGroup from "./CheckBoxGroup";
import ItemsImageEdit from "../atoms/ItemsImageEdit";
import RadioBox from "../atoms/RadioBox";
import Input from "../atoms/Input";
import CheckBox from "../atoms/CheckBox";
import Button from "../atoms/Button";
import TextArea from "../atoms/TextArea";
import { getItemImage } from "../../services/DataService";
import { useDispatch } from "react-redux";
import { setImages } from "../../redux/actions/images";
import { fetchImage } from "../../services/itemsServices";

function AddItemDashboardPopup({ card, onClose, onSave }) {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [userImage, setUserImage] = useState("");
  const [cardData, setCardData] = useState({ ...card });
  const [loadImage, setLoadImage] = useState(false);
  const lang = useSelector((state) => state.lang);
  const inputFileRef = useRef();
  const [introLang, setIntroLang] = useState("eng");
  const [image, setImage] = useState("eng");
  useEffect(() => {
    fetchImage("items", cardData._id)
      .then((res) => {
        if (res) {
          setUserImage(res);
          console.log(res);
        }
      })
      .catch((err) => {});
  }, []);

  const toolsFilters = [
    { label: "UX Design", text: "UX Design" },
    { label: "UI Design", text: "UI Design" },
    { label: "Image", text: "Image" },
    { label: "Color", text: "Color" },
    { label: "Icon", text: "Icon" },
    { label: "Font", text: "Font" },
    { label: "Video", text: "Video" },
    { label: "Audio", text: "Audio" },
    { label: "Vector", text: "Vector" },
    { label: "AI", text: "AI" },
    { label: "Animation", text: "Animation" },
    { label: "No Code", text: "No Code" },
    { label: "Productivity", text: "Productivity" },
    { label: "Others", text: "Others" },
  ];

  const appsFilter = [
    { label: "UX Design", text: "UX Design" },
    { label: "UI Design", text: "UI Design" },
    { label: "Image", text: "Image" },
    { label: "Color", text: "Color" },
    { label: "Icon", text: "Icon" },
    { label: "Font", text: "Font" },
    { label: "Video", text: "Video" },
    { label: "Audio", text: "Audio" },
    { label: "Vector", text: "Vector" },
    { label: "AI", text: "AI" },
    { label: "Animation", text: "Animation" },
    { label: "No Code", text: "No Code" },
    { label: "Productivity", text: "Productivity" },
    { label: "Others", text: "Others" },
  ];

  const resosFilters = [
    { label: "UX Design", text: "UX Design" },
    { label: "UI Design", text: "UI Design" },
    { label: "Color", text: "Color" },
    { label: "Icon", text: "Icon" },
    { label: "Font", text: "Font" },
    { label: "Image", text: "Image" },
    { label: "Video", text: "Video" },
    { label: "Audio", text: "Audio" },
    { label: "AI", text: "AI" },
    { label: "Vector", text: "Vector" },
    { label: "Animation", text: "Animation" },
    { label: "Data", text: "Data" },
    { label: "Others", text: "Others" },
  ];

  const ideazFilters = [
    { label: "UX Design", text: "UX Design" },
    { label: "UI Design", text: "UI Design" },
    { label: "Website", text: "Website" },
    { label: "App", text: "App" },
    { label: "Icon", text: "Icon" },
    { label: "Font", text: "Font" },
    { label: "Mockup", text: "Mockup" },
    { label: "Branding", text: "Branding" },
    { label: "Vector", text: "Vector" },
    { label: "AI", text: "AI" },
    { label: "Animation", text: "Animation" },
    { label: "Art", text: "Art" },
    { label: "Others", text: "Others" },
  ];

  const pricesArray = {
    tools: [
      { label: "Free", text: "Free" },
      { label: "Freemium", text: "Freemium" },
      { label: "Paid", text: "Paid" },
    ],
    apps: [
      { label: "Free", text: "Free" },
      { label: "Paid", text: "Paid" },
    ],
  };

  const platformArray = {
    tools: [
      { label: "Apple", text: "Mac" },
      { label: "Windows", text: "Win" },
      { label: "Browser", text: "Browser" },
      { label: "Linux", text: "Linux" },
    ],
    apps: [
      { label: "Apple", text: "Mac" },
      { label: "Windows", text: "Win" },
      { label: "Android", text: "Android" },
    ],
  };

  const handleAddImage = (e) => {
    let file = e.target.files[0];
    let reader = new FileReader();

    reader.onloadend = function (e) {
      setUserImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const handleSelect = (name, value) => {
    if (name === "tags") {
      setCardData((prevState) => ({
        ...prevState,
        [name]: value.split(","),
      }));
    } else if (name == "category") {
      setCardData((prevState) => ({
        ...prevState,
        category_id: [{ ...prevState.category_id, name: value }],
        category: value,
      }));
    } else {
      setCardData((prevState) => {
        return { ...prevState, [name]: value };
      });
    }
  };

  useEffect(() => {
    const getItemImageData = async (id) => {
      if (!userImage) {
        if (userImage[id] !== undefined) {
          setUserImage(userImage[id]);
        } else {
          await getItemImage(id)
            .then((res) => {
              dispatch(
                setImages({
                  [`${id}`]:
                    res !==
                    "eyJtZXNzYWdlRXJyb3IiOiJTb210aGluZyBnb2VzIHdyb25nIGluIGJhY2shIn0="
                      ? `data:image/svg+xml;base64,${res}`
                      : "",
                  ...userImage,
                })
              );
              setUserImage(
                res !==
                  "eyJtZXNzYWdlRXJyb3IiOiJTb210aGluZyBnb2VzIHdyb25nIGluIGJhY2shIn0="
                  ? `data:image/svg+xml;base64,${res}`
                  : ""
              );
            })
            .catch(() => {});
        }
      }
    };

    if (!loadImage) {
      getItemImageData(card._id);
      setLoadImage(true);
    }
  }, [card, userImage]);

  return (
    <div className="addItemDashboardPopup-container">
      <div className="addItemDashboardPopup-col-2">
        <div className="addItemDashboardPopup-render">
          <ItemCard
            category={cardData.category}
            linkAndroid={
              cardData.linkAndroid == "" ? null : cardData.linkAndroid
            }
            linkWindows={
              cardData.linkWindows == "" ? null : cardData.linkWindows
            }
            className={"item-resos-ideaz"}
            _id={cardData?._id}
            image_src={cardData?.img}
            edit={true}
            mainImage={userImage}
            title={cardData?.name}
            subtitle={
              introLang == "eng"
                ? cardData.engDescription
                : cardData.jpDescription
            }
            platform={
              (cardData.category === "tools" || cardData.category === "apps") &&
              cardData?.platform
            }
            price={
              (cardData.category === "tools" || cardData.category === "apps") &&
              cardData?.price
            }
            tags={cardData.tags}
            favs={false}
            link={cardData.link == "" ? null : cardData.link}
          />

          <div className="dashboard-edit-lang-cont">
            <div
              className={` ${
                introLang == "eng"
                  ? "dashboard-edit-lang"
                  : "dashboard-edit-lang-1"
              }`}
              onClick={() => {
                setIntroLang("eng");
              }}
            >
              <p className="dashboard-edit-lang-text">EN</p>
            </div>
            <div
              className={` ${
                introLang == "eng"
                  ? "dashboard-edit-lang-1"
                  : "dashboard-edit-lang"
              }`}
              onClick={() => {
                setIntroLang("jp");
              }}
            >
              <p className="">JP</p>
            </div>
          </div>
        </div>
        <div className="addItemDashboardPopup-form">
          {
            <div className="dashboard-edit-subcont-1">
              <div className="form-group">
                <span className="form-title">Category:</span>
                <RadioBoxGroup
                  className={"check-half check-w-auto"}
                  required={true}
                  value={
                    Object.keys(cardData) != 0 &&
                    cardData?.category_id &&
                    cardData?.category_id[0]?.name
                  }
                  name="category"
                  onChange={(e) => handleSelect("category", e)}
                  checks={[
                    { label: "tools", text: "TOOLS" },
                    { label: "resos", text: "RESOS" },
                    { label: "ideaz", text: "IDEAZ" },
                    { label: "apps", text: "APPS" },
                  ]}
                />
              </div>
              {
                <ItemsImageEdit
                  showCorped={true}
                  placeholderIcon={<PlaceholderIcon />}
                  image={userImage}
                  addIcon={<ImgIcon className="image_icon" />}
                  deleteIcon={<Trash className="image_icon" />}
                  logo={<PlaceholderIcon />}
                  onDelete={() => setUserImage("")}
                  onAddImage={() => inputFileRef.current.click()}
                  showTrash={false}
                  className={"item_photo"}
                />
              }
              <input
                type="file"
                ref={inputFileRef}
                style={{ display: "none" }}
                onChange={handleAddImage}
              />
            </div>
          }

          <div className="dashboard-halfinput-cont">
            <Input
              placeHolder={"Item Name"}
              leftIcon={<NameCardIcon />}
              onChange={(e) => handleSelect("name", e.target.value)}
              value={cardData.name}
            />
            <Input
              placeHolder={"URL Link"}
              leftIcon={<ViewLinkIcon />}
              onChange={(e) => handleSelect("link", e.target.value)}
              value={cardData.link}
            />
            {cardData.category === "apps" && (
              <>
                <Input
                  placeHolder={"Android Link"}
                  leftIcon={<ViewLinkIcon />}
                  onChange={(e) => handleSelect("linkAndroid", e.target.value)}
                  value={cardData?.linkAndroid}
                />
                <Input
                  placeHolder={"Windows Link"}
                  leftIcon={<ViewLinkIcon />}
                  onChange={(e) => handleSelect("linkWindows", e.target.value)}
                  value={cardData?.linkWindows}
                />
              </>
            )}
          </div>
          <div className="form-group">
            <TextArea
              leftIcon={<Account />}
              label={
                lang === "en" ? (
                  <> Description EN </>
                ) : (
                  <> 自己紹介（Min 30文字） </>
                )
              }
              value={cardData.engDescription}
              data-lang={lang}
              onChange={(e) => handleSelect("engDescription", e.target.value)}
            />
            <TextArea
              leftIcon={<Account />}
              label={
                lang === "en" ? (
                  <> Description JP </>
                ) : (
                  <> 自己紹介（Min 30文字） </>
                )
              }
              value={cardData.jpDescription}
              onChange={(e) => handleSelect("jpDescription", e.target.value)}
              data-lang={lang}
            />
          </div>

          <div className="form-group">
            <span className="form-title">Filter Tags:</span>
            <CheckBoxGroup
              className="check-half"
              value={cardData.filterTag}
              onChange={(value) => {
                handleSelect("filterTag", value);
              }}
              checks={
                cardData.category === "tools"
                  ? toolsFilters
                  : cardData.category === "apps"
                  ? appsFilter
                  : cardData.category === "resos"
                  ? resosFilters
                  : ideazFilters
              }
            />
          </div>
          <div className="form-group">
            <Input
              value={cardData.tags}
              onChange={(e) => handleSelect("tags", e.target.value)}
              placeHolder={"Tags"}
              leftIcon={<TagIcon />}
            />
          </div>
          {(cardData.category === "tools" || cardData.category === "apps") && (
            <div className="dashboard-edit-check-cont ">
              <div className="dashboard-edit-check form-group">
                <span className="form-title">Price:</span>
                <RadioBoxGroup
                  className={"check-half check-w-auto"}
                  required={true}
                  error={errors.position}
                  name="price"
                  value={cardData.price}
                  onChange={(value) => handleSelect("price", value)}
                  checks={pricesArray[cardData.category]}
                />
              </div>
              {card.category != "apps" && (
                <div className="dashboard-edit-check form-group">
                  <span className="form-title">Platform:</span>

                  <CheckBoxGroup
                    className="check-half"
                    error={errors.skills}
                    value={cardData.platform === null ? [] : cardData.platform}
                    onChange={(value) => {
                      handleSelect("platform", value);
                    }}
                    checks={platformArray[cardData.category]}
                  />
                </div>
              )}
            </div>
          )}
          <div className="btn-group padding-btn-20">
            <div className="dashboard-edit-buttons-cont ">
              <Button
                rightIcon={<CloseIcon />}
                className="dashboard-delete-button-1 dashboard-board-edit-button-0"
                onClick={onClose}
              >
                {lang === "en" ? "Cancel" : "キャンセル"}
              </Button>
              <Button
                rightIcon={<OK />}
                className="dashboard-board-edit-button-1"
                onClick={() => {
                  onSave(cardData, userImage);
                  onClose();
                }}
              >
                {lang === "en" ? "Save" : "保存"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddItemDashboardPopup;
