import React from "react";
import Button from "../atoms/Button";
import StaticPageSubscribe from "../../components/organismes/StaticPageSubscribe";


export function NewsLetterPopup({ onClick, showButton = false }) {
  return (
    <div className="uxer_terms_card">
          <StaticPageSubscribe link="https://script.google.com/macros/s/AKfycbxUwbfMg5EHaiXpxRtjRBrg7BTXi9T-X8OshDw5HJFfezTjIYgvQQZu6U416fQaQ9M9OA/exec" />
      {showButton && <Button onClick={onClick}>Next</Button>}
    </div>
  );
}

