import { useState, useEffect } from "react";
import { ReactComponent as Send } from "../../assets/icons/send.svg";
import { BACKEND_URL } from "../../config";
import axios from "axios";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { getUserInfo } from "../../services/authServices";
import jwtDecode from "jwt-decode";
import { toggleBlock } from "../../services/ChatService";
import React from "react";

function ChatInput({
  selectedChat,
  sendMessage,
  blockedUsers,
  handleUnblock,
  message,
  setMessage,
  handleTyping,
}) {
  const user = useSelector((state) => state.user);

  function handleSubmit(e) {
    e.preventDefault();
    if (message.replaceAll(" ", "").replace(/(\r\n|\n|\r)/gm, "") != "") {
      sendMessage(message, selectedChat, new Date().toISOString());

      setMessage("");
    }
  }

  return (
    <>
      {!blockedUsers.includes(
        selectedChat.users.find((other_user) => {
          return other_user.username !== jwtDecode(user).username;
        })._id
      ) ? (
        <form className="message-input-cont" onSubmit={handleSubmit}>
          <>
            <textarea
              className="message-input"
              value={message}
              onChange={handleTyping}
              name="chatInput"
            />

            <button className="message-input-button flex items-center justify-center">
              <Send className="message-input-button-icon" />
            </button>
          </>
        </form>
      ) : (
        <>
          <div className="message-input-cont">
            <div className="flex justify-center items-center flex-col gap-2">
              <p className="message-unblock-desc">
                You have blocked this user. You can’t send or receive messages.{" "}
              </p>
              <button
                className="message-unblock-button bg-transparent px-3 py-1 rounded-full border "
                onClick={() => {
                  toggleBlock(
                    selectedChat.users.find((other_user) => {
                      return other_user.username !== jwtDecode(user).username;
                    })._id,
                    true
                  )
                    .then(() => {
                      handleUnblock(
                        selectedChat.users.find((other_user) => {
                          return (
                            other_user.username !== jwtDecode(user).username
                          );
                        })._id
                      );
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                Unblock
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default ChatInput;
