import { useSelector } from "react-redux/es/hooks/useSelector";
import { ReactComponent as Resos } from "../../assets/icons/resos.svg";
import React, { useEffect, useState } from "react";
import { ReactComponent as News } from "../../assets/icons/news.svg";
import { ReactComponent as BagIcon } from "../../assets/icons/bag.svg";
import Button from "../atoms/Button";
import Input from "../atoms/Input";
import { ReactComponent as Send } from "../../assets/icons/send.svg";
import { ReactComponent as Press } from "../../assets/icons/press_kit.svg";
import { ReactComponent as Check } from "../../assets/icons/check.svg";
import Popup from "../molecules/Popup";
import { ReactComponent as Loading } from "../../assets/icons/loading.svg";
import { ReactComponent as CautionIcon } from "../../assets/icons/caution.svg";
import CheckBox from "../atoms/CheckBox";
import PopupContainer from "../molecules/PopupContainer";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";

import axios from "axios";

function StaticPageSubscribe({ link }) {
  const [IsShowPopup, settIsShowPopup] = useState(false);
  const [email, setEmail] = useState("");
  const lang = useSelector((state) => state.lang);
  const [error, setError] = useState("");
  const [popMessage, setPopMessage] = useState("");
  const [popIcon, setPopIcon] = useState("");
  const [popType, setPopType] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  function popups(message, type, icon) {
    settIsShowPopup(true);
    setPopType(type);
    setPopIcon(icon);
    setPopMessage(message);

    if (popType != "loading") {
      setTimeout(() => {
        settIsShowPopup(false);
      }, 3000);
    }
  }
  async function handleSubmit() {
    popups("Loading...", "loading", <Loading />);

    if (!email) {
      await setError("Email is required");
    } else if (!isValidEmail(email)) {
      await setError("Invalid email address");
    } else {
      const formData = new FormData();
      formData.append("email", email);

      await axios
        .post(link, formData)

        .then((response) => {
          if (response.data.result == "success") {
            settIsShowPopup(false);
            setOpenSuccess(true);
            setEmail("");
          } else {
            popups("Error occurred", "error", <CautionIcon />);
          }
        })
        .catch((error) => console.error("Error!", error.message));
    }
  }
  useEffect(() => {
    if (error != "") {
      popups(error, "error", <CautionIcon />);
    }
  }, [error]);
  const isValidEmail = (email) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  };
  return (
    <>
      {" "}
      <div className="sp-inner-content-box">
        <picture>
          <source media="(max-width: 960px)" srcset="img3.png" />
          <img src="img7.png" className="newsletter-image " />
        </picture>
        <div className="sp-inner-content">
          <h2 className="paragraph-title" data-lang={lang}>Sign Up for Product Updates!</h2>
          <p className="aboutus-text">
            Take the first step in growing your Instagram by signing up to our
            newsletter. No spam, only sweet content and updates.
          </p>
          <div className="newsletter-btns-box">
            <Input
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setError("");
              }}
              leftIcon={<BagIcon />}
              placeholder="Email Address"
              error={error}
              type="email"
            />

            <Button
              rightIcon={openSuccess ? <Check /> : <News />}
              className={`newsletter-btn ${
                openSuccess ? "newsletter-btn-success" : "newsletter-btn"
              }`}
              onClick={() => {
                if (!openSuccess) {
                  handleSubmit();
                }
              }}
            >
              {openSuccess ? "Done" : "Subscribe"}
            </Button>
          </div>
        </div>
      </div>
      {IsShowPopup ? (
        <Popup
          message={popMessage}
          type={popType}
          icon={popIcon}
          closePopup={() => settIsShowPopup(false)}
        />
      ) : null}
    </>
  );
}

export default StaticPageSubscribe;
