import { ReactComponent as LogoIcon } from "../assets/icons/uud.svg";
import { ReactComponent as Illustar02 } from "../assets/icons/illustra_02.svg";
import { ReactComponent as CautionIcon } from "../assets/icons/caution.svg";
import { ReactComponent as SendIcon } from "../assets/icons/send.svg";
import { ReactComponent as Illustra0 } from "../assets/icons/illustra_0.svg";
import { ReactComponent as Illustra00 } from "../assets/icons/illustra_00.svg";
import { ReactComponent as Illustra02 } from "../assets/icons/illustra_02.svg";
import { ReactComponent as MailIcon } from "../assets/icons/mail.svg";
import { ReactComponent as Illustra01 } from "../assets/icons/illustra_01.svg";
import { ReactComponent as Illustar03 } from "../assets/icons/illustra_03.svg";
import { ReactComponent as Illustar04 } from "../assets/icons/illustra_04.svg";
import { ReactComponent as Illustar06 } from "../assets/icons/illustra_06.svg";
import { ReactComponent as LogoEnIcon } from "../assets/icons/logo_type_en.svg";
import { ReactComponent as LogoJpIcon } from "../assets/icons/logo_type_jp.svg";
import { ReactComponent as ToolIcon } from "../assets/icons/tool.svg";
import { ReactComponent as ResosIcon } from "../assets/icons/resos.svg";
import { ReactComponent as IdeazIcon } from "../assets/icons/idea.svg";
import { ReactComponent as UxersIcon } from "../assets/icons/uxers.svg";
import { ReactComponent as AppsIcon } from "../assets/icons/apps.svg";
import { ReactComponent as EventIcon } from "../assets/icons/event.svg";
import { ReactComponent as XjobsIcon } from "../assets/icons/xjobs.svg";
import { ReactComponent as IntervIcon } from "../assets/icons/interv.svg";
import { ReactComponent as ProjectIcon } from "../assets/icons/project.svg";
import { ReactComponent as SmallLogoIcon } from "../assets/icons/uud3.svg";
import { ReactComponent as ContactIcon } from "../assets/icons/mail.svg";
import { ReactComponent as ServicesIcon } from "../assets/icons/services.svg";
import { ReactComponent as SignupBanner } from "../assets/icons/signup_banner.svg";
import { ReactComponent as DribbbleIcon } from "../assets/icons/dribbble_line.svg";
import { ReactComponent as InstagramIcon } from "../assets/icons/instagram_line.svg";
import { ReactComponent as TwitterIcon } from "../assets/icons/twitter_line.svg";
import { ReactComponent as FigmaIcon } from "../assets/icons/figma.svg";
import { ReactComponent as LoginIcon } from "../assets/icons/login.svg";
import { ReactComponent as SignupIcon } from "../assets/icons/signup.svg";
import { ReactComponent as ScrollAnima } from "../assets/icons/scroll_anima.svg";
import PopupContainer from "../components/molecules/PopupContainer";
import Button from "../components/atoms/Button";
import { setOpenLoginPopup, setOpenSignupPopup } from "../redux/actions/user";
import { useDispatch, useSelector } from "react-redux";
import { setSelectMenu } from "../redux/actions/env";
import {
  createNewPassword,
  forgetPassword,
  login,
} from "../services/authServices";
import CheckEmailCard from "../components/organismes/popup/CheckEmailCard";
import ForgotPasswordCard from "../components/organismes/popup/ForgotPasswordCard";
import NewPasswordCard from "../components/organismes/popup/NewPasswordCard";
import { setUser } from "../redux/actions/user";
import LoginCard from "../components/organismes/popup/LoginCard";
import Input from "../components/atoms/Input";
import Popup from "../components/molecules/Popup";
import SoonPopup from "../components/organismes/popup/SoonPopup";
import "../styles/main.scss";
import { setSelectLang } from "../redux/actions/env";
import { useEffect, useRef, useState } from "react";
import React from "react";
import pirsch from "../Analytics";
import GridItem from "../components/layout/GridItem";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { BACKEND_URL } from "../config";
function HomePage({ newPopup, closePopup }) {
  // const [openSignupPopup, setOpenSignupPopup] = useState(false);
  const [openCheckEmailPopup, setOpenCheckEmailPopup] = useState(false);

  useEffect(() => {
    document.title = "UUD - TOP";
    return () => {
      document.title = "UUD";
    };
  }, []);

  const [forgetPasswordPopup, setForgetPasswordPopup] = useState(false);
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.lang);
  const openSignupPopup = useSelector((state) => state.openSignupPopup);
  const openLoginPopup = useSelector((state) => state.openLoginPopup);
  const [checkEmailPopup, setCheckEmailPopup] = useState(false);
  const [t, i18n] = useTranslation();
  const [isSoonPopup, setIsSoonPopup] = useState(false);
  const [newPasswordPopup, setNewPasswordPopup] = useState(false);
  const [emailRecent, setEmailRecent] = useState("");
  const [email, setEmail] = useState();
  const [popMessage, setPopMessage] = useState("");
  const [popIcon, setPopIcon] = useState("");
  const [popType, setPopType] = useState("");
  const [IsShowPopup, settIsShowPopup] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [error, setError] = useState({});
  const scrollAnimeBoxRef = useRef(null);

  function popups(message, type, icon) {
    settIsShowPopup(true);
    setPopType(type);
    setPopIcon(icon);
    setPopMessage(message);
    setTimeout(() => {
      settIsShowPopup(false);
    }, 4000);
  }
  const handleLogin = (email, password) => {
    popups("Loading...", "loading");

    login(email, password, lang)
      .then((result) => {
        if (result.data.message) {
          console.log(result.data.message);
          popups(result.data.message, "error");
          setError({ ...error, login: true });
        } else if (result.data.myToken) {
          dispatch(setOpenLoginPopup(false));
          dispatch(setUser(result.data.myToken));
          window.location = "/home";
        }
      })
      .catch((error) => {
        popups(error?.response?.data.message, "error");
      });
  };
  useEffect(() => {
    const handleScroll = () => {
      const appContainer = document.querySelector(".app-container");
      const scrollAnimeBox = scrollAnimeBoxRef.current;

      if (appContainer && scrollAnimeBox) {
        const isBottom =
          appContainer.scrollHeight - appContainer.scrollTop ===
          appContainer.clientHeight;

        const hasScroll = appContainer.scrollHeight > appContainer.clientHeight;

        setIsScrollable(!isBottom && hasScroll);
      }
    };

    const appContainer = document.querySelector(".app-container");
    appContainer.addEventListener("scroll", handleScroll);

    return () => {
      appContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const isValidEmail = (email) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  };
  return (
    <>
      <div className="app-container homepage">
        <div
          className={`scroll_anime_box ${isScrollable ? "visible" : ""}`}
          ref={scrollAnimeBoxRef}
        >
          <h1>
            <ScrollAnima />
          </h1>
        </div>
        {[
          {
            child: (
              <div className="logo_top_box">
                <h3>
                  <LogoIcon />
                </h3>
                <h4>{lang === "en" ? <LogoEnIcon /> : <LogoJpIcon />}</h4>
              </div>
            ),
            className: "logo_card",
            onClick: () => {
              window.location = "/";
              pirsch("Index - Logo");
            },
          },

          {
            child: (
              <div className="slogan_box">
                <span>UXUI</span>
                <span>Designers</span>
                <h3>Home!</h3>
              </div>
            ),
            className: "slogan_card",
          },

          {
            child: (
              // <div className="access_btn_box" >
              //   <Button
              //     rightIcon={<ServicesIcon />}
              //     className="access_btn"
              //     onClick={() => null}

              //   >
              //     {lang === "en" ? "Log in" : "ログイン"}
              //   </Button>
              //   <Button
              //     rightIcon={<ServicesIcon />}
              //     className="lined_btn"
              //     onClick={() => setOpenSignupPopup(true)}

              //   >
              //     {lang === "en" ? "Sign up" : "サインアップ"}
              //   </Button>
              // </div>

              <>
                {(!localStorage.getItem("token") ||
                  localStorage.getItem("token") == "undefined") && (
                  <div className="access_btn_box">
                    <Button
                      rightIcon={<LoginIcon />}
                      className="access_btn"
                      onClick={() => {
                        dispatch(setOpenLoginPopup(true));
                      }}
                    >
                      Log in
                    </Button>
                    <Button
                      rightIcon={<SignupIcon />}
                      className="lined_btn"
                      onClick={() => {
                        dispatch(setOpenSignupPopup(true));
                        pirsch("Home - Signup");
                      }}
                    >
                      Sign up
                    </Button>
                  </div>
                )}
              </>
            ),
            className: "access_card",
          },

          {
            child: (
              <div className="service_text_box">
                <span>TOOLS</span>
              </div>
            ),
            className: "service_card tool_color",
            icon: ToolIcon,
            onClick: () => {
              dispatch(setSelectMenu("Tools"));
              window.location = "/home";
              pirsch("Index - TOOLS");
            },
          },

          {
            child: (
              <div className="soon_service_text_box">
                <span>UXERS</span>
                <h3>Soon</h3>
              </div>
            ),
            className: "soon_service_card",
            icon: UxersIcon,
          },

          {
            className: "empty_card",
          },
          {
            child: (
              <div className="service_text_box">
                <span>IDEAZ</span>
              </div>
            ),
            className: "service_card ideaz_color",
            icon: IdeazIcon,
            onClick: () => {
              dispatch(setSelectMenu("Ideaz"));
              window.location = "/home";
              pirsch("Index - IDEAZ");
            },
          },
          {
            child:
              lang === "en" ? (
                <div className="others_text_box">
                  <span>ABOUT UUD</span>
                </div>
              ) : (
                <div className="others_text_box" data-lang={lang}>
                  <span>
                    <h7>UUD</h7>とは?
                  </span>
                </div>
              ),

            className: "others_card",
            icon: SmallLogoIcon,
            onClick: () => {
              window.location = "/aboutus";
              pirsch("Index - Logo");
            },
          },

          // {
          //   child: (
          //     <>
          //       <div className="slogan_illus">
          //         <SignupBanner />{" "}
          //       </div>
          //       <div className="arrow">
          //         <ArrowIcon />{" "}
          //       </div>
          //       <div className="banner_bg">
          //         <BannerBgIcon />{" "}
          //       </div>
          //     </>
          //   ),
          //   className: "banner_box",
          //   // icon: SignupBanner,
          //   onClick: () => {
          //     window.location = "/signupsoon";
          //     pirsch("Index - ABOUT");
          //   },
          // },

          {
            child: (
              <div className="text_link_box">
                <div className="langs_btn_box">
                  <Button
                    className={`langs_btn  ${lang === "en" && "selected"}`}
                    onClick={() => {
                      localStorage.setItem(
                        "language",
                        i18n.language === "en" ? "jp" : "en"
                      );
                      i18n.changeLanguage(i18n.language === "en" ? "jp" : "en");
                      dispatch(setSelectLang("en"));
                    }}
                  >
                    English
                  </Button>
                  <Button
                    className={`langs_btn jp ${lang === "jp" && "selected"}`}
                    onClick={() => {
                      localStorage.setItem(
                        "language",
                        i18n.language === "en" ? "jp" : "en"
                      );
                      i18n.changeLanguage(i18n.language === "en" ? "jp" : "en");
                      dispatch(setSelectLang("jp"));
                    }}
                  >
                    日本語
                  </Button>
                </div>
              </div>
            ),
          },

          {
            child: (
              <div className="soon_service_text_box">
                <span>INTERV</span>
                <h3>Soon</h3>
              </div>
            ),
            className: "soon_service_card",
            icon: IntervIcon,
          },
          {
            child: (
              <div className="soon_service_text_box">
                <span>APPS</span>
              </div>
            ),
            className: "soon_service_card",
            icon: AppsIcon,
            onClick: () => {
              /*dispatch(setSelectMenu("Apps"));
              window.location = "/home";
              pirsch("Index - APPS");*/
            },
          },

          {
            child:
              lang === "en" ? (
                <div className="others_text_box">
                  <span>
                    UUD
                    <br />
                    SERVICES
                  </span>
                </div>
              ) : (
                <div className="others_text_box" data-lang={lang}>
                  <span>
                    <h7>UUD</h7>
                    <br />
                    サービス
                  </span>
                </div>
              ),
            className: "others_card",
            icon: ServicesIcon,
            onClick: () => {
              window.location = "/servicez";
              pirsch("Index - SERVICES");
            },
          },

          {
            child: (
              <div className="soon_service_text_box">
                <span>EVENTS</span>
                <h3>Soon</h3>
              </div>
            ),
            className: "soon_service_card",
            icon: EventIcon,
          },

          {
            child:
              lang === "en" ? (
                <div className="others_text_box">
                  <span>CONTACT</span>
                </div>
              ) : (
                <div className="others_text_box" data-lang={lang}>
                  <span>お問い合わせ</span>
                </div>
              ),
            className: "others_card",
            icon: ContactIcon,
            onClick: () => {
              window.location = "/contact";
              pirsch("Index - CONTACT");
            },
          },

          {
            child: (
              <div className="service_text_box">
                <span>RESOS</span>
              </div>
            ),
            className: "service_card resos_color",
            icon: ResosIcon,
            onClick: () => {
              dispatch(setSelectMenu("Resos"));
              window.location = "/home";
              pirsch("Index - RESOS");
            },
          },

          {
            child: (
              <div className="text_link_box">
                <div className="icon-set">
                  <h5>
                    <FigmaIcon
                      onClick={() => {
                        window.open("https://www.figma.com/@uud");
                        pirsch("Index - SNS - Figma");
                      }}
                    />
                  </h5>
                  <h5>
                    <TwitterIcon
                      onClick={() => {
                        window.open("https://twitter.com/uud_io");
                        pirsch("Index - SNS - Twitter");
                      }}
                    />
                  </h5>
                  <h5>
                    <InstagramIcon
                      onClick={() => {
                        window.open("https://www.instagram.com/uud.io/");
                        pirsch("Index - SNS - Instagram");
                      }}
                    />
                  </h5>
                  <h5>
                    <DribbbleIcon
                      onClick={() => {
                        window.open("https://dribbble.com/uud_io");
                        pirsch("Index - SNS - Dribbble");
                      }}
                    />
                  </h5>
                </div>
              </div>
            ),

            className: "access_card",
          },

          {
            child: (
              <div className="soon_service_text_box">
                <span>X-JOBS</span>
                <h3>Soon</h3>
              </div>
            ),
            className: "soon_service_card",
            icon: XjobsIcon,
          },

          {
            child: (
              <div className="text_link_box">
                <div>
                  <h4
                    className="link"
                    data-lang={lang}
                    onClick={() => {
                      window.location = "/Privacy";
                    }}
                  >
                    {lang === "en" ? " Privacy Policy" : "プライバシーポリシー"}
                  </h4>
                  <h5>|</h5>
                  <h4
                    className="link"
                    data-lang={lang}
                    onClick={() => {
                      window.location = "/Privacy/terms";
                    }}
                  >
                    {lang === "en" ? "Terms of use" : "利用規約"}
                  </h4>
                </div>

                <h3> UUD © 2024</h3>
              </div>
            ),
          },

          {
            className: "empty_card",
          },

          {
            child: (
              <div className="soon_service_text_box">
                <span>PROJECT</span>
                <h3>Soon</h3>
              </div>
            ),
            className: "soon_service_card",
            icon: ProjectIcon,
          },

          {
            child: <div>{/* <AppsIcon /> */}</div>,
            className: "empty_last",
          },
        ].map((item, index) => (
          <GridItem
            key={index}
            text={item.text}
            child={item.child}
            icon={item.icon}
            style={item.style}
            className={item.className}
            onClick={item.onClick}
          />
        ))}
      </div>

      {isSoonPopup && (
        <SoonPopup setIsSoonPopup={(res) => setIsSoonPopup(res)} />
      )}
      {openSignupPopup && (
        <PopupContainer
          className={"signup_top_hero"}
          cardClass={"mail_signup"}
          windowClass={"mail_signup_card"}
          innerClass={"inner_card"}
          title={lang === "en" ? "Sign up" : "サインアップ"}
          logo={<Illustra01 />}
          onClose={() => dispatch(setOpenSignupPopup(false))}
          big={true}
          illustration={<Illustra00 />}
        >
          <p className="signup-text">
            {lang === "en" ? (
              <>
                Enter your email to begin the sign-up process. Follow the link
                we send to complete your account setup.
              </>
            ) : (
              <>
                メールアドレスを入力して、アカウント作成のために送信するリンクに従ってください！
              </>
            )}
          </p>
          <div className="signup_top_box">
            <Input
              leftIcon={<MailIcon />}
              className="user-form-body-email"
              placeholder={lang === "en" ? "Email Address" : "Eメールアドレス"}
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              error={emailError}
              id="id100"
            />
            <Button
              rightIcon={<SendIcon />}
              onClick={() => {
                if (!email) {
                  setEmailError("Email field is empty!");
                  popups("Email field is empty!", "error", <CautionIcon />);
                } else if (!isValidEmail(email)) {
                  setEmailError("Check your email and try again!");
                  popups(
                    "Check your email and try again!",
                    "error",
                    <CautionIcon />
                  );
                } else {
                  popups("Loading...", "loading");
                  axios
                    .post(`${BACKEND_URL}/auth/signup_request`, {
                      email: email,
                      lang: lang,
                    })
                    .then((res) => {
                      if (res.data.status == true) {
                        setOpenCheckEmailPopup(true);
                        dispatch(setOpenSignupPopup(false));
                        settIsShowPopup(false);
                      } else {
                        popups(res.data.message, "error", <CautionIcon />);
                        setEmailError(true);
                      }
                    });
                }
              }}
              data-lang={lang}
            >
              {lang === "en" ? "Send" : "送信"}
            </Button>
          </div>
          <div className="new-account">
            <span className="back-link" data-lang={lang}>
              {lang === "en" ? (
                <>
                  Already a member?
                  <span
                    className="text-link"
                    onClick={() => {
                      dispatch(setOpenLoginPopup(true));
                      dispatch(setOpenSignupPopup(false));

                      pirsch("RM-Login");
                    }}
                  >
                    {" "}
                    Login
                  </span>
                </>
              ) : (
                <>
                  アカウントをお持ち方は
                  <span
                    className="text-link"
                    onClick={() => {
                      dispatch(setOpenLoginPopup(true));
                      dispatch(setOpenSignupPopup(false));

                      pirsch("RM-Login");
                    }}
                  >
                    {" "}
                    ログイン
                  </span>
                </>
              )}
            </span>
          </div>
        </PopupContainer>
      )}
      {IsShowPopup ? (
        <Popup
          message={popMessage}
          type={popType}
          icon={popIcon}
          closePopup={() => settIsShowPopup(false)}
        />
      ) : null}
      {openLoginPopup && (
        <PopupContainer
          title={t("popup.login.title")}
          logo={<Illustar06 />}
          big={true}
          onClose={() => dispatch(setOpenLoginPopup(false))}
          className={"signup_top_hero"}
          cardClass={"mail_signup"}
          windowClass={"mail_signup_card"}
          innerClass={"inner_card"}
          illustration={<Illustra0 />}
        >
          <LoginCard
            error={error.login}
            onLogin={(email, password) => handleLogin(email, password)}
            onForgetPassword={() => {
              dispatch(setOpenLoginPopup(false));
              setForgetPasswordPopup(true);
            }}
          />
        </PopupContainer>
      )}

      {forgetPasswordPopup && (
        <PopupContainer
          title={t("popup.forgotPassword.title")}
          logo={<Illustar04 />}
          onClose={() => setForgetPasswordPopup(false)}
        >
          <ForgotPasswordCard
            error={error.forget}
            onBack={() => {
              setForgetPasswordPopup(false);
              dispatch(setOpenLoginPopup(true));
            }}
            onSubmit={(email) => {
              popups("Loading...", "loading");
              setEmailRecent(email);
              forgetPassword(email, lang)
                .then((value) => {
                  if (value.data.message === "This email doesn't exist!!") {
                    setError({ forget: true });
                    popups(value.data.message, "error");
                  } else {
                    setForgetPasswordPopup(false);
                    setCheckEmailPopup(true);
                  }
                })
                .catch((error) => {
                  if (error.response.data.messageError) {
                    popups(error.response.data.messageError, "error");
                  }
                  console.log(error);
                });
            }}
          />
        </PopupContainer>
      )}
      {checkEmailPopup && (
        <PopupContainer
          title={t("popup.checkEmail.title")}
          logo={<Illustar02 />}
          onClose={() => setCheckEmailPopup(false)}
        >
          <CheckEmailCard
            email={emailRecent}
            onRecent={() => {
              forgetPassword(emailRecent)
                .then(() => {
                  console.log("ok");
                })
                .catch((error) => {
                  console.log(error);
                });
            }}
            onBack={() => {
              setCheckEmailPopup(false);
              dispatch(setOpenLoginPopup(true));
            }}
          />
        </PopupContainer>
      )}
      {openCheckEmailPopup && (
        <PopupContainer
          title={lang === "en" ? "Check your Email" : "メールを確認"}
          logo={<Illustra02 />}
          onClose={() => {
            setOpenCheckEmailPopup(false);
          }}
        >
          <div className="checkemail-popup-text-cont">
            {lang === "en" ? (
              <>
                <p>
                  Thank you! <br />
                  YourEmail <span>{email}</span> has been submitted.
                </p>
                <p>
                  Check your inbox for a confirmation Email to complete the sign
                  up process.
                </p>
                <p>
                  Please note, the confirmation link expires in{" "}
                  <span>48 hours.</span>
                </p>
              </>
            ) : (
              <>
                <p>
                  ご登録をありがとうございます！ <br />
                  お使いのメールアドレス <span>{email}</span> が送信されました。
                </p>
                <p>
                  受信箱に確認メールが届いているか確認してサインアップを完了してください
                  プロセス。
                </p>
                <p>
                  確認リンクの有効期限は次のとおりです。 <span>48時間。</span>
                </p>
              </>
            )}
          </div>
        </PopupContainer>
      )}
    </>
  );
}

export default HomePage;
