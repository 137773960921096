import { Buffer } from "buffer";
import { BACKEND_URL } from "../config";
import axios from "axios";

const TOOLS_URL = `${BACKEND_URL}/items/filter/tools/64761102ba22fed734b9de7a`;
const RESOS_URL = `${BACKEND_URL}/items/filter/resos-ideaz/64761222ba22fed734b9de7d`;
const IDEAZ_URL = `${BACKEND_URL}/items/filter/resos-ideaz/64761240ba22fed734b9de81`;
const APPS_URL = `${BACKEND_URL}/items/filter/tools/65a2912599ed3cb8b9886940`;

const token = localStorage.getItem("token");

export const getItems = async (
  category = "Tools",
  tags = "",
  search = "",
  platform = [],
  price = [],
  page = 1,
  limit = 60
) => {
  const CATEGORY_URLS = {
    TOOLS: TOOLS_URL,
    RESOS: RESOS_URL,
    IDEAZ: IDEAZ_URL,
    APPS: APPS_URL
  };

  category = category.toUpperCase();

  if (!CATEGORY_URLS[category.toUpperCase()]) {
    return { noData: true };
  }

  if (tags === "Favs") {
    const res = await axios.get(`${BACKEND_URL}/profile/my-favs`, {
      headers: {
        Authorization: `Bearer ${token.slice(1, -1)}`,
      },
    });

    const itemsKey = `my${category}`;
    const items = res.data[itemsKey];

    return {
      totalItems: items.length,
      items: items,
      page: page,
    };
  }

  const url = CATEGORY_URLS[category];

  const res = await axios.get(url, {
    params: {
      tag: tags,
      search: search,
      platform: platform.join(","),
      price: price.join(","),
      page: page,
    },
  });

  return { ...res.data, page: page };
};

export const fetchImage = async (type, image_path) => {
  let res;
  if (type === "items") {
    res = await axios.get(`${BACKEND_URL}/items/images/${image_path}`, {
      responseType: "arraybuffer",
      responseEncoding: "base64",
    });
  } else if (type === "profile") {
    res = await axios.get(`${BACKEND_URL}/profile/image/${image_path}`, {
      responseType: "arraybuffer",
      responseEncoding: "base64",
    });
  }
  const buffer = Buffer.from(res?.data, "base64");
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
};

export const getUxers = async () => {
  const res = await axios.get(`${BACKEND_URL}/uxers/filter`, {
    params: {
      limit: 1000,
    },
  });

  return res.data;
};
